import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { space as styledSpace, position } from 'styled-system'
import { Formik } from 'formik'
import * as Yup from 'yup'
import App from 'App'
import { space, fontSizes, radius, colors, COLOR_CONSTANTS } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from '../components/Layout'
import { H1, Text } from '../components/Typography'
import Button from '../components/Button'
import HeaderLean from '../components/HeaderLean'
import searchScreenshot from '../static/images/search_screenshot.png'
import ellipsisTopRight from '../static/svg/shape1.svg'
import ellipsisBottomLeft from '../static/svg/shape2.svg'
import Footer from '../components/Footer'

const StyledInput = styled.input`
  flex: 1;
  padding: ${space.m};
  height: 40px;
  min-width: 250px;
  width: 100%;
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  box-shadow: 0px 20px 40px rgba(39, 40, 49, 0.1);
  border-radius: ${radius.m};
  color: ${colors.primaryText};
  font-size: ${fontSizes.m};
  :focus {
    outline-style: none;
  }
  ::placeholder {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-size: ${fontSizes.m};
  }
  ${styledSpace};
`

const ErrorText = styled(Text)`
  color: ${colors.primary};
`

const StyledImg = styled.img`
  margin-top: auto;
  object-fit: contain;
  width: 1080px;
  max-width: 100%;
  height: auto;
  pointer-events: none;
`

const StyledText = styled(Text)`
  opacity: 0.8;
`

const EllipsisShape = styled.img`
  margin-top: auto;
  position: absolute;
  max-width: 100%;
  height: auto;
  z-index: -1;
  ${position};
`

const StyledBox = styled(Box)`
  background: url(${ellipsisBottomLeft}) no-repeat;
  background-position: left bottom;
`

const EmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email address')
    .required('You must input an email'),
})

const TempHome = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmitForm = async (values) => {
    const { email } = values
    try {
      setIsSubmitting(true)
      const response = await fetch(`${process.env.GATSBY_API_URL}/subscribe?email=${email}&type=prelaunch`)

      if (response.status == 500) {
        navigate('/500')
      } else {
        navigate('/thankyou')
      }
    } catch (error) {
      navigate('/500')
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <App isHeaderVisible={false}>
      <SEO title="Grow with the #1 platform for marketing agencies" path="/" />
      <StyledBox>
        <EllipsisShape src={ellipsisTopRight} top="0" right="0" />
        <HeaderLean />
        <Flex minHeight="100vh" mt="xl" px="m" flexDirection="column" alignItems="center">
          <H1 textAlign="center">Discover The Perfect Marketing Agency For Your Business</H1>
          <Box mt="l" maxWidth="600px" textAlign="center">
            <StyledText fontSize="xxl" color="secondaryText">
              Search and connect with thousands of verified social media agencies. Want early VIP access?
            </StyledText>
          </Box>
          <Grid pb="xl" mt="l" gridTemplateColumns={['1fr', 'repeat(2, auto)']} gridGap="m">
            <Formik initialValues={{ email: '' }} validationSchema={EmailValidationSchema} onSubmit={handleSubmitForm}>
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <Grid gridTemplateColumns={['1fr', 'repeat(2, auto)']} gridGap="m">
                    <Box>
                      <StyledInput
                        type="email"
                        mr="l"
                        value={values.email}
                        onChange={handleChange('email')}
                        placeholder="Your email"
                      />
                      {errors.email && touched.email && errors.email && <ErrorText mt="s">{errors.email}</ErrorText>}
                    </Box>
                    <Button.Primary type="submit" onClick={handleSubmit} isDisabled={isSubmitting}>
                      <Text>{isSubmitting ? 'SUBMITTING' : 'GET EARLY ACCESS'}</Text>
                    </Button.Primary>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
          <StyledImg src={searchScreenshot} />
        </Flex>
      </StyledBox>
      <Footer />
    </App>
  )
}

export default TempHome
